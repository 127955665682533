import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Wrapper from "../components/Resultados/wrapper"
import { Router } from "@reach/router"
import ROUTES from '../../routes.js'

import Alquiler from './propiedades'


const IndexPage = () => {
  
  const { SALE_VIEW_DATA: SALE, RENT_VIEW_DATA: RENT, TEMPORARY_VIEW_DATA: TEMPORARY } = JSON.parse(process.env.extra_data || ROUTES);
  return(
    <>
      <Seo title="Propiedades en Alquiler" />
      <Router basepath={"/" + TEMPORARY.route}>
        <Alquiler path="/" />
        <Alquiler path="/:param-1" />
        <Alquiler path="/:param-1/:param-2" />
        <Alquiler path="/:param-1/:param-2/:param-3" />
        <Alquiler path="/:param-1/:param-2/:param-3/:param-4" />
        <Alquiler path="/:param-1/:param-2/:param-3/:param-4/:param-5" />
        <Alquiler path="/:param-1/:param-2/:param-3/:param-4/:param-5/:param-6" />
        <Alquiler path="/:param-1/:param-2/:param-3/:param-4/:param-5/:param-6/:param-7" />
        <Alquiler path="/:param-1/:param-2/:param-3/:param-4/:param-5/:param-6/:param-7/:param-8" />
        <Alquiler path="/:param-1/:param-2/:param-3/:param-4/:param-5/:param-6/:param-7/:param-8/:param-9" />
        <Alquiler path="/:param-1/:param-2/:param-3/:param-4/:param-5/:param-6/:param-7/:param-8/:param-9/:param-10" />
      </Router>
    </>
  )
}
export default IndexPage